// STYLES
import "@/assets/scss/theme.scss";
import "@mdi/font/css/materialdesignicons.min.css";

// PLUGINS
import "@/plugins/luxon";
import "@/plugins/clipboard";
import "@/plugins/vee-validate";
import "@/plugins/vue-head";
import "@/plugins/vue-query";

import Vue, {watch} from "vue";
import vuetify from "@/plugins/vuetify";
import keycloak from "@/plugins/keycloak";
import * as Sentry from "@sentry/vue";
import VueMatomo from 'vue-matomo';

// PINIA
import {createPinia, PiniaVuePlugin} from 'pinia';
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import {useFLMStore} from "@/stores/flm";
import {useAuthStore} from "@/stores/auth";
import {useAppStore} from "@/stores/app";

// API
import "@/api";

// App
import App from "@/App.vue";
import router from "./router";
import {isNavigationFailure} from "vue-router";

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    integrations: [
      Sentry.replayIntegration({
        stickySession: false,
      }),
      Sentry.browserTracingIntegration({
        router,
        routeLabel: 'path',
        beforeStartSpan: (context) => {
          return {
            ...context,
            name: `/#${context.name}`,
          };
        },
      }),
    ],
    tracesSampleRate: Number(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE ?? 0),
    replaysOnErrorSampleRate: Number(process.env.VUE_APP_SENTRY_REPLAY_SAMPLE_RATE ?? 0),
    replaysSessionSampleRate: Number(process.env.VUE_APP_SENTRY_REPLAY_SAMPLE_RATE ?? 0),
    autoSessionTracking: true,
    tracePropagationTargets: [process.env.VUE_APP_API_BASE_URL],
    async beforeSend(event, hint) {
      if (isNavigationFailure(hint.originalException)) return null;

      return event;
    },
  });
}

if (process.env.VUE_APP_MATOMO_HOST) {
  Vue.use(VueMatomo, {
    host: process.env.VUE_APP_MATOMO_HOST,
    siteId: Number(process.env.VUE_APP_MATOMO_SITE_ID ?? 1),
    router,
  });
}

keycloak.init({onLoad: "login-required"}).then(async (auth) => {
  if (!auth) {
    return;
  }

  const flmStore = useFLMStore(pinia);
  const appStore = useAppStore(pinia);
  const authStore = useAuthStore(pinia);

  // update roles and fetch index before anything else
  try {
    await flmStore.fetchIndex();
  } catch (e) {
    console.error("could not fetch from backend", e);
  }
  authStore.updateRoles();

  watch(() => [flmStore.siteId, flmStore.utilityId], () => {
    appStore.initNavigation();
  });

  Sentry.getCurrentScope().setUser( {
    id: keycloak.tokenParsed?.sub,
    email: keycloak.tokenParsed?.email,
    username: keycloak.tokenParsed?.name,
  });

  flmStore.$subscribe(() => {
    const route = router.currentRoute;
    if (route.meta?.hasAuth && !route.meta.hasAuth(authStore, flmStore)) {
      // this is like guaranteed to raise an error because index instantly redirects in a guard
      router.push({name: 'index'}).catch(() => {});
    }
  }, {detached: true});

  // create app
  new Vue({
    vuetify,
    router,
    pinia,
    el: "#app",
    beforeCreate() {
      appStore.init();
      appStore.initNavigation();

      if (keycloak.tokenParsed) {
        // @ts-ignore
        this.$matomo?.setUserId(keycloak.tokenParsed.sub);
      }
    },
    render: (h) => h(App),
  });
}).catch((e) => {
  console.error("Could not initialize keycloak-js adapter.");
  console.error(e);
});
